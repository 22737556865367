<template>
  <div class="app-SiteMap">
    <div class="SiteMap-wrapper">
      <el-row>
        <el-col :span="24">
          <div class="grid-content bg-purple-dark" style="text-align: left">
            <h1 style="font-size: 30px">{{ this.$t('Sitemap') }}</h1>
          </div>
          <div style="display: flex">
            <el-col :span="6" style="display: flex; flex-direction: column">
              <ul style="text-align: left">
                <li><a href="">Вся продукция</a></li>
                <li><a href="">Косметическая продукция</a></li>
                <li><a href="">Крепкий иммунитет</a></li>
                <li><a href="">Продукция для мужчин</a></li>
                <li><a href="">Продукция для женщин</a></li>
                <li><a href="">Комплексы для похудения</a></li>
                <li><a href="">Гигиена</a></li>
                <li><a href="">Товары для дома</a></li>
              </ul>
            </el-col>
            <el-col :span="18">
              <ul style="text-align: left;width: 35%;margin: 0 auto;">
                <li>
                      <a href="">{{ this.$t('活动') }}</a>
                    </li>
                <li>
                  <a href="">{{ this.$t('个人账户') }}</a>
                  <ul>
                    <li>
                      <a href="">{{ this.$t('个人信息') }}</a>
                    </li>
                    <li>
                      <a href="">{{ this.$t('密码') }}</a>
                    </li>
                    <li>
                      <a href="">{{ this.$t('我的地址') }}</a>
                    </li>
                    <li>
                      <a href="">{{ this.$t('订单历史') }}</a>
                    </li>
                    <li>
                      <a href="">{{ this.$t('可下载文件') }}</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="">{{ this.$t('查看购物篮') }}</a>
                </li>
                <li>
                  <a href="">{{ this.$t('下单') }}</a>
                </li>
                <li>
                  <a href="">{{ this.$t('搜索') }}</a>
                </li>
                <li>
                  <a href="">{{ this.$t('信息') }}</a>
                  <ul>
                    <li><a href="">{{ this.$t('Доставка и Самовывоз') }}</a></li>
                    <li><a href="">{{ this.$t('關於我們') }}</a></li>
                    <li><a href="">{{ this.$t('公開發售協議') }}</a></li>
                    <li><a href="">{{ this.$t('如何在網站上下訂單') }}</a></li>
                    <li><a href="">{{ this.$t('安全政策') }}</a></li>
                    <li><a href="">{{ this.$t('證明書') }}</a></li>
                    <li><a href="">{{ this.$t('隱私政策') }}</a></li>
                    <li><a href="">{{ this.$t('与我们联系') }}</a></li>
                  </ul>
                </li>
              </ul>
            </el-col>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SiteMap',
  data() {
    return {}
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {},
}
</script>

<style lang="scss">
.el-tabs__item.is-active {
  color: #f39f43 !important;
  font-size: 26px;
  font-weight: 600;
}

.el-tabs__item {
  color: #333;
  font-size: 22px;
}
.MsoNormal {
  margin: 10px 0px;
  text-align: left;
}
a {
  text-decoration: none;
  font-family: Solomon_Sans;
  font-weight: 300;
  color: #333333;
  font-size: 14px;
  line-height: 20px;
}
</style>
